import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './css/style.css'

function Reject() {
    return (
        <Container id="exploding-santa">
            <Box sx={{height: '100vh' }} />
        </Container>
    );
}

export default Reject